import React from 'react';

const Skeleton = ({ gradient }) => {

    return (
        <div className='Skeleton'>
            <div className="nav-shimmer"></div>
            <div className={`registration-header ${gradient}`}>

                <div className="outer-div">
                    <div className="join-camp-shimmer"></div>
                    <div className="join-camp-shimmer"></div>
                    <div className="join-camp-shimmer"></div>
                    <div className="join-camp-shimmer"></div>

                    <div className="closes-shimmer"></div>

                    <div className="time-shimmer">
                        <div className="box"></div>
                        <div className="box"></div>
                        <div className="box"></div>
                        <div className="box"></div>
                    </div>

                    <div className="button-container-shimmer"></div>
                </div>

                <div className="phase1-image-grid-shimmer"></div>

            </div>
        </div >
    );
};

export default Skeleton;
