import React, { Suspense } from 'react';
import { useState } from 'react'
import './App.scss'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Skeleton from './Skeleton';
// import ThankYou from './components/common/thankYou/ThankYou';
const ThankYou = React.lazy(() => import('./components/common/thankYou/ThankYou'));


// Lazy load components
const Landing21 = React.lazy(() => import('./components/pages/21Days/21Days'));
const Landing7 = React.lazy(() => import('./components/pages/7Days/7Days'));
const Hours200 = React.lazy(() => import('./components/pages/200Hours/200Hours'));
const NutriDiet = React.lazy(() => import('./components/pages/nutriDiet/nutriDiet'));
const Hours200Course1 = React.lazy(() => import('./components/pages/200Hours/200HoursCourse1'));

function App() {

  return (
    <>
      <Routes>

        <Route path="/" element={
          <Suspense fallback={<Skeleton gradient={'gradient-21days'} />}>
            <Landing21 />
          </Suspense>}
        />

        <Route path="/21days" element={
          <Suspense fallback={<Skeleton gradient={'gradient-21days'} />}>
            <Landing21 />
          </Suspense>}
        />

        <Route path="/7days" element={
          <Suspense fallback={<Skeleton gradient={'gradient-7days'} />}>
            <Landing7 />
          </Suspense>}
        />

        <Route path="/200hours" element={
          <Suspense fallback={<Skeleton gradient={'gradient-200hrs'} />}>
            <Hours200 />
          </Suspense>}
        />

        <Route path="/Selective200HourCourse" element={
          <Suspense fallback={<Skeleton gradient={'gradient-200hrs'} />}>
            <Hours200Course1 />
          </Suspense>}
        />


        <Route path="/nutriDiet" element={
          <Suspense fallback={<Skeleton gradient={'gradient-nutriDiet'} />}>
            <NutriDiet />
          </Suspense>}
        />



        <Route path='/ThankYou-21days' element={<ThankYou />} />
        <Route path='/ThankYou-200hours' element={<ThankYou />} />
        <Route path='/ThankYou-Selective200hours' element={<ThankYou />} />
        <Route path='/ThankYou-7days' element={<ThankYou />} />
        <Route path='/ThankYou-NutriDiet' element={<ThankYou />} />

      </Routes>
    </>
  )
}

export default App
